import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Surrounded by seven mountains, the second biggest city of Norway is said to
be the entrance to the fjords - and to great mountain bike trails!`}</p>
    <br />
    <p>{`The cityscape is defined
by the sea, islands, mountains and the busy harbour. Old trade houses crouch
to the waterfront. Big ships are tied at the docks. A cruise ship lands, and
floods the historical center with thousands of tourists. It's time for us to
move on and hit the trails just outside the city.`}</p>
    <TrailguidePlugin content="lat=60.3983&lng=5.3455&coverage=10" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open this map in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=11,60.3983,5.3455"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Ulriken`}</h4>
    <p>{`Not far from the center, the Ulriken lift brings us out of city and to the start
of scenic trails. Bergen stretches out to the surrounding hills and islands.`}</p>
    <Image src="destinations/bergen_3.jpg" mdxType="Image" />
    <h4>{`Ulriken lift`}</h4>
    <p>{`The small gondolas can take a few bikes and bikers. Right below the gondola,
you already get an impression of the very rough and rocky downhill track of Bergen.`}</p>
    <Image src="destinations/bergen_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Enduro Baby`}</h4>
    <p>{`Luckily we go for an easier track first, called Enduro Baby. It starts mellow
with a great view over Bergen and the sea. Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/175"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/bergen_5.jpg" mdxType="Image" />
    <p>{`Map of the black Endurobaby trail. It starts at the top station of the Ulriken cable car.
A technical climb takes care of a good warm up before the descent starts:`}</p>
    <TrailguidePlugin content="lat=60.357692716972515&lng=5.401938445866109&coverage=0.1" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open this map in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=13,60.36476486441117,5.399864216853919"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Technical steeps`}</h4>
    <p>{`Soon it becomes more technical with steep, rocky sections.`}</p>
    <Image src="destinations/bergen_6.jpg" mdxType="Image" />
    <h4>{`Rocks`}</h4>
    <p>{`Bergen is well known for its rocky and rough terrain. When it is dry, the
rocks invite to a creative line choice. But when it is wet, you just have to
go where gravity takes you. Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/175"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/bergen_9.jpg" mdxType="Image" />
    <br />
    <h4>{`Toro`}</h4>
    <p>{`Further in the back country, close to the highest mountain
around Bergen (Gullfjellet, 914m), there is a great ridgeline called Toro.`}</p>
    <TrailguidePlugin content="lat=60.35756470203668&lng=5.566557012498379&coverage=0.1" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open this map in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=13,60.36476486441117,5.399864216853919"
        }}>{`web-app`}</a></p>
    </p>
    <Image src="destinations/bergen_14.jpg" mdxType="Image" />
    <p>{`Typical is the access by pushbiking. The advantage is that we can
already check out the lines for the descent. It starts alpine and technical
with a few switchbacks. Then the ridge becomes flatter and the trail straight
and fast.`}</p>
    <Image src="destinations/bergen_15.jpg" mdxType="Image" />
    <h4>{`Sædalen`}</h4>
    <p>{`There are not only rocky and rough trails. You find some built and flowy ones as well.
Sædalen is a small playground with jumps and berms.`}</p>
    <Image src="destinations/bergen_12.jpg" mdxType="Image" />
    <p>{`You can even get some good airtime.`}</p>
    <Image src="destinations/bergen_11.jpg" mdxType="Image" />
    <h4>{`Bergen MTB Club`}</h4>
    <p>{`Thanks a lot to the `}<a parentName="p" {...{
        "href": "https://bergenmtb.com/"
      }}>{`Bergen MTB Club`}</a>{`
for the great guiding and the good advice in line choice in the tricky rock sections.`}</p>
    <Image src="destinations/bergen_2.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      